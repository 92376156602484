@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300..700&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");
@import "bootstrap/dist/css/bootstrap.min.css";
@import "animate.css";

body {
  font-family: "Urbanist", "Urbanist Placeholder", sans-serif !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.navbar-brand {
  svg {
    margin-right: 10px;
    width: 50px;
    height: 50px;
  }
}

.bg-light {
  .navbar-brand {
    svg {
      width: 30px;
      height: 30px;
    }
  }
}

.features-container {
  .card {
    border-radius: 30px;
  }
}

.feature-card {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.8), rgba(246, 244, 242, 0.8));
  backdrop-filter: blur(10px);
  border-radius: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 0.3s ease;

  .card-title {
    font-size: 1.75rem;
    font-weight: 600;
    margin-bottom: 1rem;

    svg {
      fill: t;
    }
  }

  .card-text {
    font-size: 1rem;
    line-height: 2;
    color: #666;
  }
}

.steps-container {

  .step-card,
  .step-cards {
    background-color: rgb(255, 255, 255);
    width: 100%;
    border-radius: 31px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 5px 20px 0px;
    opacity: 1;
    padding: 5px;

    .card-body {
      border: 1px solid rgb(243, 243, 241);
      border-radius: 28px;
      background: linear-gradient(rgb(255, 255, 255) 50%, rgb(246, 244, 242) 100%);
    }

    .muted {
      color: #999;
    }

    .card-title {
      text-align: left;
      font-weight: 600;
      font-family: "Urbanist", "Urbanist Placeholder", sans-serif;
    }

    .card-text {
      text-align: left;
      font-size: 1.15rem;
      color: #555;
    }
  }
}

.step-cards {
  height: 100%;
}

.section-title {
  font-weight: 600;
  font-size: 3rem;
}

h1 {
  font-size: 3.5rem !important;
}

.align-left {
  text-align: left;
}

@media (max-width: 768px) {
  .steps-container {
    gap: 2rem;
  }

  .step-card {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  .step-card .card-body {
    padding: 1rem;
  }

  .step-card h3,
  .step-card h4 {
    font-size: 1.25rem;
  }

  .step-card p {
    font-size: 1rem;
  }
}

/* FAQ Section */
.faq-section {
  background-color: #f3f3f1;
  padding-bottom: 4rem;
  width: 75%;
  margin: 0 auto;
  border-radius: 10px;
}

.faq-section h2 {
  font-size: 3rem;
  font-weight: 600;
}

.faq-section p {
  font-size: 1.25rem;
  line-height: 1.5;
}

.accordion {
  border: none;
  width: 75%;
  margin: 0 auto;
}

.accordion-item {
  border: none;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 5px 20px 0px;
  margin-bottom: 1rem;
  background-color: white;
  width: 100%;
}

.accordion-button {
  font-size: 1.1rem;
  font-weight: 600;
  color: #002851;
  background-color: transparent;
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.accordion-button::after {
  font-size: 1.2rem;
  margin-left: 1rem;
  transition: transform 0.3s ease;
}

.accordion-button:not(.collapsed)::after {
  transform: rotate(180deg);
}

.accordion-body {
  font-size: 1rem;
  line-height: 1.5;
  color: #666;
  padding: 1rem;
  border-bottom: none;
}

@media (max-width: 768px) {
  .faq-section h2 {
    font-size: 2.5rem;
  }

  .faq-section p {
    font-size: 1rem;
  }

  .accordion {
    width: 100%;
  }

  .accordion-button {
    font-size: 0.8rem;
  }

  .accordion-button::after {
    font-size: 0.8rem;
  }

  .accordion-body {
    font-size: 0.9rem;
  }
}

.form-control {
  border: none;
  background-color: #f8f9fa;
}

.form-control:focus {
  box-shadow: none;
  background-color: #f8f9fa;
}

.cta-btn:hover {
  opacity: 0.9;
}

.cta-btn {
  background-color: #002851;
  color: white;
  border-radius: 30px;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  transition: all 0.3s ease;
  border: none;
}

.rounded-4 {
  border-radius: 1rem !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

/* Banner */
.banner {
  padding: 5rem 0;

  .hero-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    text-align: left;
  }

  .hero-subtitle {
    font-size: 1.25rem;
    color: #333;
    text-align: left;
  }
}

/* Team Scroll Container */
.team-scroll-container {
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding-bottom: 10px;
}

.team-scroll-container::-webkit-scrollbar {
  display: none;
}

.team-scroll-wrapper {
  display: flex;
  gap: 20px;
  width: max-content;
  padding: 20px;
}

.team-card {
  min-width: 250px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

/* Footer */
.footer {
  background-color: white;
  color: black;
  padding-top: 2rem;
  box-shadow: #282c34 solid 1px 2px 10px;
}

.footer h5,
.footer h6 {
  font-weight: 600;
}

.footer p {
  font-size: 1rem;
  line-height: 1.5;
}

.footer a {
  color: black;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer a:hover {
  color: #136cef;
  text-decoration: none;
}

.footer .instagram:hover {
  color: #E1306C;
}

.footer .github:hover {
  color: black;
}

.footer .youtube:hover {
  color: red;
}

.social-links {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.social-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s;
}

.social-link:hover {
  transform: scale(1.1);
}

.footer-link {
  font-size: 0.9rem;
  color: #999;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer .nav-link {
    font-size: 0.8rem;
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(135deg, rgb(205, 225, 244) 0%, rgb(95, 167, 244) 100%);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(135deg, rgb(205, 225, 244) 0%, rgb(95, 167, 244) 100%);
}

.cookie-declaration-wrapper {
  position: relative;
  margin: 20px 0;

  #CookieDeclaration {
    max-width: 1200px;
    margin: 0 auto;

    
    table {
      width: 100%;
      margin-bottom: 1rem;

      th,
      td {
        padding: 0.75rem;
        vertical-align: top;
        border-top: 1px solid #dee2e6;
      }

      thead th {
        vertical-align: bottom;
        border-bottom: 2px solid #dee2e6;
      }
    }

    
    a {
      color: #007bff;
      text-decoration: none;

      &:hover {
        color: #0056b3;
        text-decoration: underline;
      }
    }

    
    h2,
    h3 {
      margin-bottom: 1rem;
      font-weight: 500;
    }

    
    p {
      margin-bottom: 1rem;
    }

    
    button {
      @extend .btn;
      @extend .btn-primary;
      margin: 0.25rem;
    }
  }
}

@media (max-width: 768px) {
  .cookie-declaration-wrapper {
    margin: 10px 0;

    #CookieDeclaration {
      table {
        display: block;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
      }
    }
  }
}